exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-ddd-tsx": () => import("./../../../src/pages/app/ddd.tsx" /* webpackChunkName: "component---src-pages-app-ddd-tsx" */),
  "component---src-pages-app-index-tsx": () => import("./../../../src/pages/app/index.tsx" /* webpackChunkName: "component---src-pages-app-index-tsx" */),
  "component---src-pages-app-submissions-index-tsx": () => import("./../../../src/pages/app/submissions/index.tsx" /* webpackChunkName: "component---src-pages-app-submissions-index-tsx" */),
  "component---src-pages-app-submissions-website-id-index-tsx": () => import("./../../../src/pages/app/submissions/[websiteId]/index.tsx" /* webpackChunkName: "component---src-pages-app-submissions-website-id-index-tsx" */),
  "component---src-pages-app-submissions-website-id-plugin-id-tsx": () => import("./../../../src/pages/app/submissions/[websiteId]/[pluginId].tsx" /* webpackChunkName: "component---src-pages-app-submissions-website-id-plugin-id-tsx" */),
  "component---src-pages-app-widget-index-tsx": () => import("./../../../src/pages/app/widget/index.tsx" /* webpackChunkName: "component---src-pages-app-widget-index-tsx" */),
  "component---src-pages-app-widget-website-id-index-tsx": () => import("./../../../src/pages/app/widget/[websiteId]/index.tsx" /* webpackChunkName: "component---src-pages-app-widget-website-id-index-tsx" */),
  "component---src-pages-app-widget-website-id-plugin-id-tsx": () => import("./../../../src/pages/app/widget/[websiteId]/[pluginId].tsx" /* webpackChunkName: "component---src-pages-app-widget-website-id-plugin-id-tsx" */),
  "component---src-pages-cancellation-and-refund-policy-tsx": () => import("./../../../src/pages/cancellation-and-refund-policy.tsx" /* webpackChunkName: "component---src-pages-cancellation-and-refund-policy-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-logout-tsx": () => import("./../../../src/pages/logout.tsx" /* webpackChunkName: "component---src-pages-logout-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-purchase-success-tsx": () => import("./../../../src/pages/purchase-success.tsx" /* webpackChunkName: "component---src-pages-purchase-success-tsx" */),
  "component---src-pages-purchase-tsx": () => import("./../../../src/pages/purchase.tsx" /* webpackChunkName: "component---src-pages-purchase-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

